'use strict';

const { methods } = require('./carousel');

(() => {
    const CITRUS_COMPONENT_ID = 'homepage-citrus';
    let $tabButtons = $('.js-carousel-tab');
    let hasCitrus = false;
    let isCitrusFirst = false;
    let isPageLoad = true;

    /**
     * Removes the first category button and clicks on the next button in line
     */
    const resetTabButtonsAndCallFirstCarousel = () => {
        $tabButtons.first().parent().remove();

        $tabButtons = $('.js-carousel-tab'); // reset the variable
        $tabButtons.first().click();
    };

    /**
     * Highlights the correct category button based on user selection
     * @param {string} componentId - The ID of the Page Designer component
     */
    const adjustTabButtons = (componentId) => {
        $('.js-category-buttons').removeClass('d-none');
        $('.js-category-buttons-skeleton').addClass('d-none');

        $('.js-carousel-tab').removeClass('selected');
        $(`.js-carousel-tab[data-component-id="${componentId}"]`).addClass('selected');

        if (!$('.js-category-buttons .js-owl-carousel').hasClass('owl-loaded')) {
            setTimeout(() => {
                const config = {
                    margin: 16,
                    dots: false,
                    autoWidth: true,
                    checkVisibility: false,
                    slideBy: 2,
                    navText: ['<i class="icon icon-arrow-left-circle"></i>', '<i class="icon icon-arrow-right-circle"></i>']
                };

                $('.js-owl-carousel').owlCarousel(config);
            }, 10);
        }

        $tabButtons.removeClass('disabled');
    };

    /**
     * Gets the Page Designer component and places it on the page
     * @param {string} componentId - The ID of the Page Designer component
     * @param {string} url - URL for the Page-Show endpoint to fetch the component
     * @returns {void}
     */
    const getCarouselComponent = async (componentId, url) => {
        const componentSelector = `js-carousel-tab-component-${componentId}`;
        const $carouselComponentsPlaceholder = $('.js-carousel-tab-placeholder');
        const $carouselComponents = $('.js-carousel-tab-component');
        let $currentCarouselComponent = $(`.${componentSelector}`);

        if ($currentCarouselComponent.length) {
            $carouselComponents.addClass('d-none');
            $currentCarouselComponent.removeClass('d-none');
            $carouselComponentsPlaceholder.removeClass('d-none');
            $('body').trigger('custom:carouselSwitcherDisplay', [true]);

            adjustTabButtons(componentId);

            if (componentId === CITRUS_COMPONENT_ID) {
                $('.js-homepage-citrus-ads').trigger('refresh.owl.carousel');
            }

            return;
        }

        $('body').trigger('custom:carouselSwitcherDisplay', [false]);
        $carouselComponents.addClass('d-none');

        let response = null;

        try {
            response = await new Promise((resolve, reject) => {
                $.get(url)
                    .done((data) => {
                        resolve(data);
                    })
                    .fail((error) => {
                        reject(error);
                    });
            });
        } catch (_) {
            if (isPageLoad) {
                resetTabButtonsAndCallFirstCarousel();

                return;
            }

            adjustTabButtons(componentId);
            return;
        }

        $currentCarouselComponent = $($.parseHTML(`<div class="js-carousel-tab-component ${componentSelector}">${response}</div>`));

        if (componentId === CITRUS_COMPONENT_ID) {
            $carouselComponentsPlaceholder.append($currentCarouselComponent);
            // hide the Citrus placeholder. It will be populated through the Citrus callback function
            $currentCarouselComponent.addClass('d-none');

            return;
        }

        // carousel is empty
        if (!$currentCarouselComponent.find('.js-product-tile').length && isPageLoad) {
            resetTabButtonsAndCallFirstCarousel();

            return;
        }

        $carouselComponentsPlaceholder.append($currentCarouselComponent);
        adjustTabButtons(componentId);

        methods.initRecommandationSlider(
            $currentCarouselComponent,
            {
                checkVisibility: false
            },
            {
                hideSkeletonAnimation: true
            }
        );

        isPageLoad = false;
    };

    $tabButtons.on('click', function () {
        $tabButtons.addClass('disabled');
        getCarouselComponent($(this).attr('data-component-id'), $(this).attr('data-component-url'));
    });

    $tabButtons.each(function (index) {
        if ($(this).attr('data-component-id') === CITRUS_COMPONENT_ID) {
            isCitrusFirst = index === 0;
            hasCitrus = true;

            $(this).trigger('click');
        }
    });

    if (!hasCitrus) {
        $tabButtons.first().trigger('click');
    }

    $('body').on('custom:carouselSwitcherDisplay', function (e, shouldRemoveSkeleton) {
        $('.js-carousel-tab-placeholder').toggleClass('mt-5', !shouldRemoveSkeleton);
        $('.js-skeleton-cards').toggleClass('d-none', shouldRemoveSkeleton);
    });

    $('body').on('custom:carouselSwitcherCitrusCallback', function (e, hasElements) {
        const citrusCarouselComponent = $(`.js-carousel-tab-component-${CITRUS_COMPONENT_ID}`);

        if (!hasElements) {
            citrusCarouselComponent.remove();
            $(`.js-carousel-tab[data-component-id="${CITRUS_COMPONENT_ID}`).parent().remove();

            $('.js-carousel-tab').first().trigger('click');

            return;
        }

        if (isCitrusFirst) {
            adjustTabButtons(CITRUS_COMPONENT_ID);
            $('body').trigger('custom:carouselSwitcherDisplay', [true]);
            citrusCarouselComponent.removeClass('d-none');

            return;
        }

        $tabButtons.first().trigger('click');
    });
})();
